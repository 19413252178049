<template>
  <b-container class="bv-row" align-v="center">
    <b-col align-v="center">
      <h1>Oops!</h1>
      <p>Looks like you don't have permission to view this page!</p>
      <router-link :to="{ name: 'Login' }">Take me to login.</router-link>
    </b-col>
  </b-container>
</template>

<script>
export default {
  name: "SecurityMessage",
};
</script>
